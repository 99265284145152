import { FaLinkedin } from "react-icons/fa";
import { ImXing } from "react-icons/im";

import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            {/*<!-- for small screens -->*/}
            <div className="w3-hide-medium w3-hide-large">
                <div className="w3-row">
                    {
                        {
                            de: (
                                <div className="w3-col s6">
                                    Kontakt:
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="tel:+491775620249"
                                        style={{ textDecoration: "none" }}>
                                        +49 (0) 177 562 0249
                                    </a>
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="mailto:info@raimundschmoll.de"
                                        style={{ textDecoration: "none" }}>
                                        info@raimundschmoll.de
                                    </a>
                                </div>
                            ),
                            en: (
                                <div className="w3-col s6">
                                    Contact:
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="tel:+491775620249"
                                        style={{ textDecoration: "none" }}>
                                        +49 (0) 177 562 0249
                                    </a>
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="mailto:info@raimundschmoll.de"
                                        style={{ textDecoration: "none" }}>
                                        info@raimundschmoll.de
                                    </a>
                                </div>
                            )
                        }[global.language]
                    }
                    <div className="w3-col s6">
                        <div style={{ float: "right" }}>
                            <Link className="w3-hover-text-grey" to="/imprint">
                                {{ de: "Impressum", en: "Imprint" }[global.language]}
                            </Link>
                            <br />
                            <Link className="w3-hover-text-grey" to="/dataprotection">
                                {{ de: "Datenschutz", en: "Data Protection" }[global.language]}
                            </Link>
                            <br />
                            <a
                                href="https://www.linkedin.com/in/raimund-schmoll-9549298a/"
                                target="_blank"
                                rel="noopener noreferrer">
                                <FaLinkedin size={25} />
                            </a>{" "}
                            <a
                                href="https://www.xing.com/profile/Raimund_Schmoll/cv"
                                target="_blank"
                                rel="noopener noreferrer">
                                <ImXing size={25} />
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="w3-center">
                    © Copyright 2023 Raimund Schmoll -{" "}
                    {{ de: "Alle Rechte vorbehalten", en: "All rights reserved" }[global.language]}
                </div>
            </div>
            {/*<!-- for medium and large screens -->*/}
            <div className="w3-hide-small">
                <div className="w3-row">
                    {
                        {
                            de: (
                                <div className="w3-col w3-third">
                                    Kontakt:
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="tel:+491775620249"
                                        style={{ textDecoration: "none" }}>
                                        +49 (0) 177 562 0249
                                    </a>
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="mailto:info@raimundschmoll.de"
                                        style={{ textDecoration: "none" }}>
                                        info@raimundschmoll.de
                                    </a>
                                </div>
                            ),
                            en: (
                                <div className="w3-col w3-third">
                                    Contact:
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="tel:+491775620249"
                                        style={{ textDecoration: "none" }}>
                                        +49 (0) 177 562 0249
                                    </a>
                                    <br />
                                    <a
                                        className="w3-hover-text-grey"
                                        href="mailto:info@raimundschmoll.de"
                                        style={{ textDecoration: "none" }}>
                                        info@raimundschmoll.de
                                    </a>
                                </div>
                            )
                        }[global.language]
                    }
                    <div className="w3-col w3-third w3-center" style={{ marginTop: "8px" }}>
                        © Copyright 2023 Raimund Schmoll -{" "}
                        {{ de: "Alle Rechte vorbehalten", en: "All rights reserved" }[global.language]}
                    </div>
                    <div className="w3-col w3-third">
                        <div style={{ float: "right" }}>
                            <Link className="w3-hover-text-grey" to="/imprint">
                                {{ de: "Impressum", en: "Imprint" }[global.language]}
                            </Link>
                            <br />
                            <Link className="w3-hover-text-grey" to="/dataprotection">
                                {{ de: "Datenschutz", en: "Data Protection" }[global.language]}
                            </Link>
                            <br />
                            <a
                                href="https://www.linkedin.com/in/raimund-schmoll-9549298a/"
                                target="_blank"
                                rel="noopener noreferrer">
                                <FaLinkedin size={25} />
                            </a>{" "}
                            <a
                                href="https://www.xing.com/profile/Raimund_Schmoll/cv"
                                target="_blank"
                                rel="noopener noreferrer">
                                <ImXing size={25} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
