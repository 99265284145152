let language;

const germanDomains = ["raimundschmoll.de", "www.raimundschmoll.de"];
const englishDomains = ["raimundschmoll.com", "www.raimundschmoll.com"];

if (germanDomains.includes(window.location.host)) {
    language = "de";
} else if (englishDomains.includes(window.location.host)) {
    language = "en";
}

// this if-statement can be deleted for production
if (window.location.host === "localhost:3000") {
    language = "de";
} else if (window.location.host === "127.0.0.1:3000") {
    language = "en";
}

global.language = language;

export default global.language;
