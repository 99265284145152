import { useState } from "react";
import Vita from "../Vita";

export default function Team() {
    const [showVita, setShowVita] = useState(false);
    return (
        <>
            {
                {
                    de: (
                        <>
                            <h2>Unser Team</h2>
                            <div className="teamImagesContainer">
                                <div onClick={() => setShowVita((prev) => !prev)} style={{ cursor: "pointer"}}>
                                    <img
                                        src="/raimund.jpg"
                                        alt="Raimund Schmoll"
                                        className="teamImage"
                                    />
                                    <div style={{ cursor: "inherit"}}>Raimund Schmoll - Lösungsfinder</div>
                                </div>
                                <br />
                                <div>
                                    <img
                                        src="/susanne.jpg"
                                        alt="Susanne Sabielny"
                                        className="teamImage"
                                    />
                                    <div>Susanne Sabielny - Backoffice / Travel-Managerin</div>
                                </div>
                            </div>
                        </>
                    ),
                    en: (
                        <>
                            <h2>Our Team</h2>
                            <div className="teamImagesContainer">
                                <div onClick={() => setShowVita((prev) => !prev)} style={{ cursor: "pointer"}}>
                                    <img
                                        src="/raimund.jpg"
                                        alt="Raimund Schmoll"
                                        className="teamImage"
                                    />
                                    <div style={{ cursor: "inherit"}}>Raimund Schmoll - solution finder</div>
                                </div>
                                <br />
                                <div>
                                    <img
                                        src="/susanne.jpg"
                                        alt="Susanne Sabielny"
                                        className="teamImage"
                                    />
                                    <div>Susanne Sabielny - back office / travel manager</div>
                                </div>
                            </div>
                        </>
                    )
                }[global.language]
            }
            <Vita show={showVita} />
        </>
    );
}
