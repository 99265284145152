export default function Imprint() {
    return {
        de: (
            <>
                <h2>Impressum</h2>
                <h4>Angaben gemäß § 5 TMG:</h4>
                <div>
                    Lösungsfinder
                    <br />
                    Raimund Schmoll
                    <br />
                </div>
                <h4>Postanschrift:</h4>
                <div>
                    Wibbeltweg 6<br />
                    33758 Schloß Holte-Stukenbrock
                </div>
                <h4>Kontakt:</h4>
                <div>
                    Telefon: +49 (0) 177 562 0249
                    <br />
                    E-Mail: info@raimundschmoll.de
                </div>
                <h4>Umsatzsteuer-Identifikationsnummer:</h4>
                <div>DE362566755</div>
                <br /><br />
                Diese Website wurde von{" "}
                <a href="https://owl.computer" target="_blank" rel="noopener noreferrer">
                    <strong>OWL Computer</strong>
                </a>{" "}
                erstellt.
            </>
        ),
        en: (
            <>
                <h2>Imprint</h2>
                <h4>According to § 5 TMG:</h4>
                <div>
                    Lösungsfinder
                    <br />
                    Raimund Schmoll
                    <br />
                </div>
                <h4>Postal address:</h4>
                <div>
                    Wibbeltweg 6<br />
                    33758 Schloß Holte-Stukenbrock
                </div>
                <h4>Contact:</h4>
                <div>
                    Tel.: +49 (0) 177 562 0249
                    <br />
                    E-Mail: info@raimundschmoll.de
                </div>
                <h4>VAT identification number:</h4>
                <div>DE362566755</div>
                <br /><br />
                This website was created by{" "}
                <a href="https://owl.computer" target="_blank" rel="noopener noreferrer">
                    <strong>OWL Computer</strong>
                </a>
                .
            </>
        )
    }[global.language];
}
