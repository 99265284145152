export default function DataProtection() {
    return {
        de: (
            <>
                <h2>Datenschutzerklärung</h2>
                <div style={{ textAlign: "left" }}>
                    <p>
                        Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der
                        Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
                        Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
                    </p>
                    <p>
                        Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
                        (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
                        automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                        personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die
                        Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
                        Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich
                        oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
                    </p>
                    <p>
                        Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang,
                        Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder
                        allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem
                        informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der
                        Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener
                        Verantwortung verarbeiten.
                    </p>
                    <p>
                        Unsere Datenschutzerklärung ist wie folgt gegliedert:
                        <br />
                        I. Informationen über uns als Verantwortliche
                        <br />
                        II. Rechte der Nutzer und Betroffenen
                        <br />
                        III. Informationen zur Datenverarbeitung
                    </p>
                    <h2>I. Informationen über uns als Verantwortliche</h2>
                    <p>
                        Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:
                        Lösungsfinder
                        <br />
                        Raimund Schmoll
                        <br />
                        Wibbeltweg 6<br />
                        33758, Schloss Holte-Stukenbrock
                        <br />
                        Telefon: +49 (0) 177 562 0249
                        <br />
                        E-Mail: info@raimundschmoll.de
                    </p>
                    <h2>II. Rechte der Nutzer und Betroffenen</h2>
                    <p>
                        Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und
                        Betroffenen das Recht
                    </p>
                    <ul>
                        <li>
                            auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die
                            verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der
                            Daten (vgl. auch Art. 15 DSGVO);{" "}
                        </li>
                        <li>
                            auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art.
                            16 DSGVO);
                        </li>
                        <li>
                            auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder,
                            alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf
                            Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;
                        </li>
                        <li>
                            auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung
                            dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
                        </li>
                        <li>
                            auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie
                            betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen
                            verarbeitet werden (vgl. auch Art. 77 DSGVO).
                        </li>
                    </ul>
                    <p>
                        Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den
                        Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die
                        Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu
                        unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit
                        einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf
                        Auskunft über diese Empfänger.
                    </p>
                    <p>
                        <b>
                            Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen
                            die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach
                            Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch
                            gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.
                        </b>
                    </p>
                    <h2>III. Informationen zur Datenverarbeitung</h2>
                    <p>
                        Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt,
                        sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen
                        Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen
                        Verarbeitungsverfahren gemacht werden.
                    </p>
                    <h3>Allgemeine Verlinkung auf Profile bei Drittanbietern</h3>
                    <p>
                        Der Anbieter setzt auf der Website eine Verlinkung auf die nachstehend aufgeführten sozialen
                        Netzwerke ein.
                        <br />
                        Rechtsgrundlage ist hierbei Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse des Anbieters
                        besteht an der Verbesserung der Nutzungsqualität der Website.
                        <br />
                        Die Einbindung der Plugins erfolgt dabei über eine verlinkte Grafik. Erst durch einen Klick auf
                        die entsprechende Grafik wird der Nutzer zu dem Dienst des jeweiligen sozialen Netzwerks
                        weitergeleitet.
                        <br />
                        Nach der Weiterleitung des Kunden werden durch das jeweilige Netzwerk Informationen über den
                        Nutzer erfasst. Dies sind zunächst Daten wie IP-Adresse, Datum, Uhrzeit und besuchte Seite. Ist
                        der Nutzer währenddessen in seinem Benutzerkonto des jeweiligen Netzwerks eingeloggt, kann der
                        Netzwerk-Betreiber ggf. die gesammelten Informationen des konkreten Besuches des Nutzers dem
                        persönlichen Account des Nutzers zuordnen. Interagiert der Nutzer über einen „Teilen“-Button des
                        jeweiligen Netzwerks, können diese Informationen in dem persönlichen Benutzerkonto des Nutzers
                        gespeichert und ggf. veröffentlicht werden. Will der Nutzer verhindern, dass die gesammelten
                        Informationen unmittelbar seinem Benutzerkonto zugeordnet werden, muss sich der Nutzer vor dem
                        Anklicken der Grafik ausloggen. Zudem besteht die Möglichkeit, das jeweilige Benutzerkonto
                        entsprechend zu konfigurieren.
                        <br />
                        Folgende soziale Netzwerke sind vom Anbieter verlinkt:
                        <br />
                        - Linkedin
                        <br />- XING
                    </p>
                </div>
            </>
        ),
        en: (
            <>
                <h2>Privacy Policy</h2>
                <div style={{ textAlign: "left" }}>
                    <p>
                        Personal data (usually referred to just as "data" below) will only be processed by us to the
                        extent necessary and for the purpose of providing a functional and user-friendly website,
                        including its contents, and the services offered there.
                    </p>
                    <p>
                        Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation
                        (hereinafter referred to as the "GDPR"), "processing" refers to any operation or set of operations
                        such as collection, recording, organization, structuring, storage, adaptation, alteration,
                        retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making
                        available, alignment, or combination, restriction, erasure, or destruction performed on personal
                        data, whether by automated means or not.
                    </p>
                    <p>
                        The following privacy policy is intended to inform you in particular about the type, scope,
                        purpose, duration, and legal basis for the processing of such data either under our own control or
                        in conjunction with others. We also inform you below about the third-party components we use to
                        optimize our website and improve the user experience which may result in said third parties also
                        processing data they collect and control.
                    </p>
                    <p>
                        Our privacy policy is structured as follows:
                        <br />
                        I. Information about us as controllers of your data
                        <br />
                        II. The rights of users and data subjects
                        <br />
                        III. Information about the data processing
                    </p>
                    <h2>I. Information about us as controllers of your data</h2>
                    <p>
                        The party responsible for this website (the "controller") for purposes of data protection law is:
                        <br />
                        Lösungsfinder
                        <br />
                        Raimund Schmoll
                        <br />
                        Wibbeltweg 6<br />
                        33758, Schloss Holte-Stukenbrock
                        <br />
                        Telefon: +49 (0) 177 562 0249
                        <br />
                        E-Mail: info@raimundschmoll.de
                    </p>
                    <h2>II. The rights of users and data subjects</h2>
                    <p>
                        With regard to the data processing to be described in more detail below, users and data subjects
                        have the right
                    </p>
                    <ul>
                        <li>
                            to confirmation of whether data concerning them is being processed, information about the data
                            being processed, further information about the nature of the data processing, and copies of
                            the data (cf. also Art. 15 GDPR);
                        </li>
                        <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li>
                        <li>
                            to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or, alternatively,
                            if further processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict said
                            processing per Art. 18 GDPR;
                        </li>
                        <li>
                            to receive copies of the data concerning them and/or provided by them and to have the same
                            transmitted to other providers/controllers (cf. also Art. 20 GDPR);
                        </li>
                        <li>
                            to file complaints with the supervisory authority if they believe that data concerning them is
                            being processed by the controller in breach of data protection provisions (see also Art. 77
                            GDPR).
                        </li>
                    </ul>
                    <p>
                        In addition, the controller is obliged to inform all recipients to whom it discloses data of any
                        such corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para.
                        1, 18 GDPR. However, this obligation does not apply if such notification is impossible or involves
                        a disproportionate effort. Nevertheless, users have a right to information about these recipients.
                    </p>
                    <p>
                        <b>
                            Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the
                            controller’s future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In
                            particular, an objection to data processing for the purpose of direct advertising is
                            permissible.
                        </b>
                    </p>
                    <h2>III. Information about the data processing</h2>
                    <p>
                        Your data processed when using our website will be deleted or blocked as soon as the purpose for
                        its storage ceases to apply, provided the deletion of the same is not in breach of any statutory
                        storage obligations or unless otherwise stipulated below.
                    </p>
                    <h3>General introduction</h3>
                    <p>General linking to third-party profiles</p>
                    <p>
                        The provider includes a link on the website to the social media listed below.
                        <br />
                        The legal basis for this is Article 6 para. 1 lit. f GDPR. The legitimate interest of the provider
                        is to improve the quality of use of the website.
                        <br />
                        The plugins are integrated via a linked graphic. The user is only forwarded to the service of the
                        respective social media by clicking on the corresponding graphic.
                        <br />
                        After the customer has been forwarded, information about the user is recorded by the respective
                        social media. This is initially data such as IP address, date, time and page visited. If the user
                        is logged into his/her user account of the respective social media at the same time, the social
                        media operator can, if required, assign the information collected from the user’s specific visit
                        to the user’s personal account. If the user interacts via a “Share” button of the respective
                        social media, this information can be stored in the user’s personal user account and, if required,
                        be published. If the user wants to prevent the collected information from being directly assigned
                        to his/her user account, the user must log out before clicking on the graphic. It is also possible
                        to configure the respective user account accordingly.
                        <br />
                        The following social media are linked by the provider:
                        <br />
                        - Linkedin
                        <br />- XING
                    </p>
                </div>
            </>
        )
    }[global.language];
}
