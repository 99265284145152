import { useRef, useEffect } from "react";

export default function Vita({ show }) {
    const vita = useRef(null);
    useEffect(() => {
        show && vita?.current?.scrollIntoView();
    }, [show]);
    if (!show) {
        return null;
    }
    return {
        de: (
            <div ref={vita} className="w3-responsive myCareer">
                <table className="w3-table-all">
                    <caption>
                        <h2>Werdegang Raimund Schmoll</h2>
                    </caption>
                    <tbody>
                        <tr>
                            <th>Zeitraum</th>
                            <th>Tätigkeit</th>
                        </tr>
                        <tr>
                            <td>08/2021 - 11/2022</td>
                            <td>
                                <strong>Sales Director</strong> P+L Innovations GmbH, Bad Krozingen
                                <ul>
                                    <li>Vertrieb Medizinprodukt "teilbares Rollstuhlrad" Trivida</li>
                                    <li>globale Vertriebsverantwortlichkeit mit Fokus Europa</li>
                                    <li>
                                        signifikanter Auf- und Ausbau der Netzwerke von Sanitätshäusern und Distributoren
                                        in Deutschland und weltweit
                                    </li>
                                    <li>OEM Projektleitung</li>
                                    <li>Marketing- und Budgetplanung</li>
                                    <li>Aufbau und Leitung Sales Team (Budget 2023)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>01/2019 - 11/2020</td>
                            <td>
                                <strong>Sales Manager Europa</strong> Alsanza Medizintechnik und Pharma GmbH, Pfullingen
                                <ul>
                                    <li>Vertrieb Ophthalmologie (IOL) und Orthopädie (Hyaluron)</li>
                                    <li>
                                        Business Development Europa, Auf- und Ausbau neuer Vertriebs- und
                                        Distributorennetzwerke
                                    </li>
                                    <li>Marketing- und Budgetplanung</li>
                                    <li>Teamleitung Europa, APAC, MEA, LATAM</li>
                                    <li>35 neue Vertriebspartner für Europa in 2019 neu generiert</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>09/2018 - 01/2019</td>
                            <td>
                                <strong>Senior Key Account Manager</strong> Innovation Health Partners GmbH, Berlin
                                <ul>
                                    <li>Vertrieb Smart Home Konzepte (AAL)</li>
                                    <li>Management Health / Immobilien / Kommunen / Politik</li>
                                    <li>Business Development neue Märkte (national & international)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>03/2014 - 09/2017</td>
                            <td>
                                <strong>International Business Development Manager</strong> Mangar International Ltd,
                                Presteigne, Wales / UK
                                <ul>
                                    <li>Vertrieb medizinischer Hilfsmittel Europa und weltweit</li>
                                    <li>Key-Account- und Projektmanagement, Marketing</li>
                                    <li>Umsatz-, Personal- und Budgetplanung</li>
                                    <li>Verantwortung und Aufbau Team DACH von 6 Mitarbeitern</li>
                                    <li>Aufbau Distributoren/Händler-Netzwerke Europa und weltweit</li>
                                    <li>Kundenbestand von 3 auf über 500 gesteigert</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>11/2011 - 02/2014</td>
                            <td>
                                <strong>Sales Manager</strong> MIS Implants Technologies GmbH, Minden
                                <ul>
                                    <li>Vertrieb von Implantaten, Prothetik, Biomaterialien und 3-D-Technologie</li>
                                    <li>Account Management von Zahnärzten, Laboren und Kliniken</li>
                                    <li>OP-Begleitung und medizinischer Support</li>
                                    <li>Erschließung neuer Märkte und Vertriebswege</li>
                                    <li>signifikantes Umsatz- und Kundenwachstum</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>09/2009 - 10/2011</td>
                            <td>
                                <strong>Sales Manager</strong> Topcart GmbH, Wiesbaden
                                <ul>
                                    <li>Vertrieb von Druckkonzepten und IT-Software</li>
                                    <li>Akquise von Unternehmen/Kommunen</li>
                                    <li>Marketing- und Budgetverantwortung</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>04/2008 - 06/2009</td>
                            <td>
                                <strong>Reiseverkehrskaufmann</strong> Reisebüro Kompass, Bielefeld
                                <ul>
                                    <li>Kundenberatung und Verkauf von Individualreisen</li>
                                    <li>Betreuung von Geschäftskunden</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>10/2004 - 03/2008</td>
                            <td>
                                <strong>Reiseverkehrskaufmann</strong> Lufthansa City Center Top Service, Köln
                                <ul>
                                    <li>Vermittlung und Verkauf von Reisen und anderen touristischen Dienstleistungen</li>
                                    <li>Verantwortung touristisches Segment</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>09/2002 - 09/2004</td>
                            <td>
                                <strong>Verkaufsbüroleiter</strong> TUI Leisure Travel GmbH, Köln
                                <ul>
                                    <li>Filialleitung und Verantwortung für 8 Mitarbeiter</li>
                                    <li>Umsatz-, Personal- und Budgetplanung</li>
                                    <li>Durchführung von touristischem Marketing und Vertrieb</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>01/1999 - 06/2002</td>
                            <td>
                                <strong>Travel Manager</strong> Lufthansa City Center Top Service GmbH, Köln
                                <ul>
                                    <li>Leitung Travel Management Firma Oerlikon (ehemals Leybold)</li>
                                    <li>Management von Geschäftsreisen</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>08/1996 - 01/1999</td>
                            <td>
                                <strong>Ausbildung zum Reiseverkehrskaufmann</strong> (Abschluss IHK) Lufthansa City
                                Center Top Service GmbH, Köln
                            </td>
                        </tr>
                        <tr>
                            <td>09/1995 - 06/1996</td>
                            <td>
                                <strong>Bundeswehr Düren</strong> (Wehrdienst)
                            </td>
                        </tr>
                        <tr>
                            <td>09/1986 - 06/1995</td>
                            <td>
                                <strong>Dreikönigsgymnasium Köln</strong> (Abitur)
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        ),
        en: (
            <div ref={vita} className="w3-responsive myCareer">
                <table className="w3-table-all">
                    <caption>
                        <h2>Career Path of Raimund Schmoll</h2>
                    </caption>
                    <tbody>
                        <tr>
                            <th>Period</th>
                            <th>Position</th>
                        </tr>
                        <tr>
                            <td>08/2021 - 11/2022</td>
                            <td>
                                <strong>Sales Director</strong> at P+L Innovations GmbH, Bad Krozingen
                                <ul>
                                    <li>Sales of medical product "divisible wheelchair wheel" Trivida</li>
                                    <li>Global sales responsibility with focus on Europe</li>
                                    <li>
                                        Significant establishment and expansion of networks with medical supply stores and
                                        distributors in Germany and worldwide
                                    </li>
                                    <li>OEM project management</li>
                                    <li>Marketing and budget planning</li>
                                    <li>Establishment and management of sales team (Budget 2023)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>01/2019 - 11/2020</td>
                            <td>
                                <strong>Sales Manager Europe</strong> at Alsanza Medizintechnik und Pharma GmbH,
                                Pfullingen
                                <ul>
                                    <li>Sales of ophthalmology (IOL) and orthopedics (hyaluronan)</li>
                                    <li>
                                        Business development in Europe, establishing and expanding new sales and
                                        distributor networks
                                    </li>
                                    <li>Marketing and budget planning</li>
                                    <li>Team leadership for Europe, APAC, MEA, LATAM</li>
                                    <li>Generated 35 new distribution partners for Europe in 2019</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>09/2018 - 01/2019</td>
                            <td>
                                <strong>Senior Key Account Manager</strong> at Innovation Health Partners GmbH, Berlin
                                <ul>
                                    <li>Sales of smart home concepts (AAL)</li>
                                    <li>Management of health, real estate, municipal, and political sectors</li>
                                    <li>Business development in new markets (national & international)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>03/2014 - 09/2017</td>
                            <td>
                                <strong>International Business Development Manager</strong> at Mangar International Ltd,
                                Presteigne, Wales / UK
                                <ul>
                                    <li>Sales of medical aids in Europe and worldwide</li>
                                    <li>Key account and project management, marketing</li>
                                    <li>Sales, personnel, and budget planning</li>
                                    <li>
                                        Responsible for establishing and managing a team of 6 employees for DACH region
                                    </li>
                                    <li>Establishment of distributor networks in Europe and worldwide</li>
                                    <li>Increased customer base from 3 to over 500</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>11/2011 - 02/2014</td>
                            <td>
                                <strong>Sales Manager</strong> at MIS Implants Technologies GmbH, Minden
                                <ul>
                                    <li>Sales of implants, prosthodontics, biomaterials, and 3D technology</li>
                                    <li>Account management for dentists, laboratories, and clinics</li>
                                    <li>Operating room support and medical assistance</li>
                                    <li>Exploration of new markets and sales channels</li>
                                    <li>Significant revenue and customer growth</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>09/2009 - 10/2011</td>
                            <td>
                                <strong>Sales Manager</strong> at Topcart GmbH, Wiesbaden
                                <ul>
                                    <li>Sales of printing concepts and IT software</li>
                                    <li>Acquisition of corporate and municipal clients</li>
                                    <li>Marketing and budget responsibility</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>04/2008 - 06/2009</td>
                            <td>
                                <strong>Travel Agent</strong> at Reisebüro Kompass, Bielefeld
                                <ul>
                                    <li>Customer consultation and sales of individual trips</li>
                                    <li>Corporate client management</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>10/2004 - 03/2008</td>
                            <td>
                                <strong>Travel Agent</strong> at Lufthansa City Center Top Service, Cologne
                                <ul>
                                    <li>Booking and sales of travel and other tourism services</li>
                                    <li>Responsibility for the tourism segment</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>09/2002 - 09/2004</td>
                            <td>
                                <strong>Branch Manager</strong> at TUI Leisure Travel GmbH, Cologne
                                <ul>
                                    <li>Branch management and responsibility for 8 employees</li>
                                    <li>Sales, personnel, and budget planning</li>
                                    <li>Implementation of tourism marketing and sales</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>01/1999 - 06/2002</td>
                            <td>
                                <strong>Travel Manager</strong> at Lufthansa City Center Top Service GmbH, Cologne
                                <ul>
                                    <li>Head of Travel Management for Oerlikon company (formerly Leybold)</li>
                                    <li>Management of corporate travel</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>08/1996 - 01/1999</td>
                            <td>
                                <strong>Apprenticeship as a Travel Agent</strong> (IHK certification) at Lufthansa City
                                Center Top Service GmbH, Cologne
                            </td>
                        </tr>
                        <tr>
                            <td>09/1995 - 06/1996</td>
                            <td>
                                <strong>German Armed Forces</strong> (Military service) in Düren
                            </td>
                        </tr>
                        <tr>
                            <td>09/1986 - 06/1995</td>
                            <td>
                                <strong>Dreikönigsgymnasium Cologne</strong> (High School Diploma)
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }[global.language];
}
