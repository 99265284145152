import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import Home from "./Pages/Home";
import BusinessPackages from "./Pages/BusinessPackages";
import Team from "./Pages/Team";
import Imprint from "./Pages/Imprint";
import DataProtection from "./Pages/DataProtection";
import DNA from "./Pages/DNA";
import Footer from "./Footer";
import CurrentCustomers from "./Pages/CurrentCustomers";
import News from "./Pages/News";
import PageNotFound from "./Pages/PageNotFound";

export default function App() {
    return (
        <div className="App">
            <Header />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/businessPackages" element={<BusinessPackages />} />
                <Route exact path="/team" element={<Team />} />
                <Route exact path="/imprint" element={<Imprint />} />
                <Route exact path="/dataprotection" element={<DataProtection />} />
                <Route exact path="/cologneconstitution" element={<DNA />} />
                <Route exact path="/currentcustomers" element={<CurrentCustomers />} />
                <Route exact path="/news" element={<News />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Footer />
        </div>
    );
}
