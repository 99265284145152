export default function BusinessPackages() {
    return {
        de: (
            <div>
                <h2>Business Modelle (Preise auf Anfrage)</h2>
                <div className="w3-responsive">
                    <table className="w3-table-all">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Basis Paket (Sales Support ohne Kundenbesuche)</th>
                                <th>Marketing & Web Paket</th>
                                <th>Akquise Paket light (Kundenbesuche exkl. Kaltakquise)</th>
                                <th>Management Paket (Messe Support, KPI, KOL, Influencer, Produktdesign ...)</th>
                                <th>Akquise Paket XL (Kundenbesuche inkl. Kaltakquise, KAM, BDM, Ausschreibungen)</th>
                            </tr>
                            <tr>
                                <td className="bronze">Bronze</td>
                                <td>✓</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="silver">Silber</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="gold">Gold</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="platin">Platin</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="diamond">Diamant</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h2>Leistungen</h2>
                <table className="w3-table-all">
                    <tbody>
                        <tr>
                            <th>Option</th>
                            <th>enthalten</th>
                        </tr>
                        <tr>
                            <td className="bronze">Bronze</td>
                            <td>
                                <ul>
                                    <li>
                                        Firmenrepräsentant, Kommunikation ausschließlich aus dem Home Office ohne Kundenbesuche
                                    </li>
                                    <li>Promotion auf der RS Website, als auch Nennung auf der Firmenwebsite des Partners</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="silver">Silber*</td>
                            <td>
                                <ul>
                                    <li>
                                        Marketing und Web support, wie z.B. Übersetzung von Dokumenten in andere Sprachen, Domains
                                        für Vertriebspartner im Ausland (hier können zusätzliche Kosten entstehen.)
                                    </li>
                                    <li>
                                        Support innovatives und modernes Marketing (Facebook, Instagram, Tik-Tok, ChatGPT ...)
                                        (hier können zusätzliche Kosten entstehen.)
                                    </li>
                                    <li>
                                        Marketing Strategie in Kooperation mit einer der erfolgreichsten und erfahrensten
                                        Agenturen Deutschlands (hier können zusätzliche Kosten entstehen.)
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="gold">Gold*</td>
                            <td>
                                <ul>
                                    <li>
                                        Kundenbesuche basierend auf Leads der Firma als auch direkten Kundenanfragen, beinhaltet
                                        keine Kaltakquise (individuelle Abstimmung wie viele Termine erwartet werden / möglich
                                        sind)
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="platin">Platin*</td>
                            <td>
                                <ul>
                                    <li>Messe Support (als Ansprechpartner vor Ort, Messeorganisation)</li>
                                    <li>KPI basierende Budgetplanung</li>
                                    <li>KOL und Influencer Management (hier können zusätzliche Kosten entstehen.)</li>
                                    <li>Produktdesign (hier können zusätzliche Kosten entstehen.)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="diamond">Diamant*</td>
                            <td>
                                <ul>
                                    <li>
                                        Kundenbesuche auf Basis Goldpaket, zusätzlich inkl. Kaltakquise (individuelle Abstimmung
                                        wie viele Termine erwartet werden / möglich sind)
                                    </li>
                                    <li>
                                        Key Account Management, Business Development, Projekt Management incl. Ausschreibungen
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>*Pakete enthalten immer auch die Leistungen aller kleineren Pakete.</p>
            </div>
        ),
        en: (
            <div>
                <h2>business models (prices on request)</h2>
                <div className="w3-responsive">
                    <table className="w3-table-all">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Base Package (sales rep Germany, no customer visits)</th>
                                <th>Marketing & Web package</th>
                                <th>Acquisation package light (customer visits excl. cold calling)</th>
                                <th>Management package (exhibition Support, KPI, KOL, Influencer, product design ...)</th>
                                <th>Acquisation package XL (customer visits incl. cold calling, KAM, BDM, tender management)</th>
                            </tr>
                            <tr>
                                <td className="bronze">Bronze</td>
                                <td>✓</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="silver">Silver</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="gold">Gold</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="platin">Platinum</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="diamond">Diamond</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h2>Services</h2>
                <table className="w3-table-all">
                    <tbody>
                        <tr>
                            <th>Option</th>
                            <th>Included</th>
                        </tr>
                        <tr>
                            <td className="bronze">Bronze</td>
                            <td>
                                <ul>
                                    <li>general company representation in Germany on internal base (home office) without</li>
                                    <li>promotion on RS website, as well if desired on company website of contract customer</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="silver">Silver*</td>
                            <td>
                                <ul>
                                    <li>
                                        support for company web domain in Germany including translation of documents and domain
                                        contents (extra costs may apply)
                                    </li>
                                    <li>
                                        support of innovative and modern marketing (Facebook, Instagram, Tik-Tok,
                                        ChatGPT...)(extra costs may apply)
                                    </li>
                                    <li>
                                        marketing strategy in cooperation with one of the most successful and experienced agencies
                                        on the medical field (extra costs may apply)
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="gold">Gold*</td>
                            <td>
                                <ul>
                                    <li>
                                        customer visits based on company leads, customer requests (no cold calling included) / how
                                        many visits expected and how many visits possible needs to be discussed individually
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="platin">Platinum*</td>
                            <td>
                                <ul>
                                    <li>exhibition support (sales rep, organisation)</li>
                                    <li>KPI based sales strategy</li>
                                    <li>KOL and Influencer (extra costs may apply)</li>
                                    <li>Product design (extra costs may apply)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="diamond">Diamond*</td>
                            <td>
                                <ul>
                                    <li>
                                        customer visits in addition to light package including cold calling / how many visits
                                        expected and how many visits possible needs to be discussed individually
                                    </li>
                                    <li>Key Account Management, Business Development, Project Managemnt incl. Tenders</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>*Packages always include the services of all smaller packages.</p>
            </div>
        )
    }[global.language];
}
