import { Link } from "react-router-dom";
import rschmoll from "../images/rschmoll.jpg";

export default function Home() {
    return (
        <div>
            <img className="RaimundIMG" src={rschmoll} alt="Raimund Schmoll" />
            {
                {
                    de: (
                        <>
                            <h2>„Ich bin ein Lösungsfinder“</h2>
                            <p style={{ textAlign: "left" }}>
                                Mein Name ist Raimund Schmoll, und ich habe in meinen mehr als 25 Jahren Berufstätigkeit
                                festgestellt, dass ich immer mit den unterschiedlichsten Problemen und Herausforderungen
                                konfrontiert wurde und zum Spezialisten darin wurde, entsprechende Lösungen zu finden und
                                anzubieten.
                                <br />
                                <br />
                                Daher können von meinen maßgeschneiderten Lösungskonzepten grundsätzlich alle Branchen
                                profitieren. Der Fokus liegt allerdings in den Bereichen Dental, Medizintechnik,
                                Ophthalmologie und Orthopädie, da ich dort über 10 Jahre Vertriebserfahrung sammeln
                                durfte. Auch für völlig neue Projekte und Branchen bin ich offen, wenn die Chemie und die
                                Aufgabenstellung stimmen.
                                <br />
                                <br />
                                Ich unterstütze Sie in den Bereichen, die mir im Blut liegen: Von Vertrieb über Promotion,
                                Marketing und Messe bis hin zu Akquise und Projektmanagement finde ich die geeignete
                                Lösung für Ihre Aufgabenstellung.
                                <br />
                                <br />
                                Sprechen Sie mich an, denn Kommunikation bleibt die Basis jedes Erfolgs.
                                <br />
                                <br />
                                Meine maßgeschneiderten Lösungskonzepte passen auf eine einzige Seite, sollen für alle
                                verständlich und einfach umzusetzen sein, ohne versteckte Zusatzkosten.
                                <br />
                                <br />
                                <b>
                                    <Link to="/businessPackages">Hier</Link>
                                </b>{" "}
                                finden Sie die richtige Lösung!
                            </p>
                        </>
                    ),
                    en: (
                        <>
                            <h2>“I am a solution finder”</h2>
                            <p style={{ textAlign: "left" }}>
                                My name is Raimund Schmoll and in almost more than 25 years of professional activity I
                                have found that I have always been confronted with a wide variety of problems/challenges
                                and have always tried to find and offer an appropriate solution, so everyone can benefit
                                from my customized solutions.
                                <br />
                                <br />
                                Therefore, basically all industries can benefit from my tailor-made solution concepts.
                                However, the focus is on the areas of dentistry, medical technology, ophthalmology and
                                orthopedics, as I was able to gain more than 10 years of sales experience there. I am also
                                open to completely new projects and industries if the chemistry and the task are right.
                                <br />
                                <br />
                                I will support you in the areas that are in my blood: from sales, promotion, marketing and
                                trade fairs to acquisition and project management, I will find the right solution for your
                                task.
                                <br />
                                <br />
                                Talk to me, because communication remains the basis of every success.
                                <br />
                                <br />
                                My customized solutions fit on a single page, are understandable for everyone and easy to
                                implement, with no hidden additional costs.
                                <br />
                                <br />
                                <b>
                                    <Link to="/businessPackages">Here</Link>
                                </b>{" "}
                                you will find the right solution!
                            </p>
                        </>
                    )
                }[global.language]
            }
            <div>
                <h2>That's what my business partners say</h2>
                <div className="reference">
                    <p>
                        Was braucht ein erfolgreiches Unternehmen? – Kreativität, Engagement & Umsetzungsstärke!
                        <br />
                        Darum war es ein Glücksfall, Raimund in der schwierigen Zeit des Firmenaufbaus im Team zu haben.
                        Nie zuvor habe ich einen Menschen getroffen, der so vor Ideen sprüht. Der alles und jedes aus so
                        unterschiedlichen Perspektiven ansehen kann, dass man immer einen Lösungsweg findet. Und vor allem
                        setzt sich Raimund für die Umsetzung guter Ideen mit vollem Einsatz überzeugend ein. Raimund ist
                        ein Gewinn für jedes Team, welches offen für Neues und Ungewohntes ist. Und es macht viel Freude,
                        den Lösungsweg mit ihm gemeinsam zu gehen. Danke für Deine Zeit bei uns.
                    </p>
                    <span>Michael Eich - Produktmanager Trivida</span>
                </div>
                <div className="reference">
                    <p>
                        We worked very successfully together during the time at MIS Implants from 2011 to 2014 and I can
                        highly recommend Raimund to any new employer. During that time, Raimund has been able to unlock
                        many unopened doors with this at that time new 3-D technology, and as a Sales Manager has brought
                        many new customers to MIS Implants. As a trusted and reliable person, he also had the ability to
                        inspire and respond to the needs of individual dentists. We are still engaged in lively exchanges,
                        which testifies to a harmonious and extremely good business relationship from back then.
                    </p>
                    <span>Ulf Neveling - Camlog Vertriebs GmbH</span>
                </div>
                <div className="reference">
                    <p>
                        On behalf of Boncura Direkt, I would like to take this opportunity to once again extend my
                        heartfelt thanks for your extraordinary commitment and successful collaboration with your Mangar
                        lifting cushions during your active involvement with Mangar International as Business Development
                        Manager Europe until 2017, as I am guess you and your departure from Manger have always regretted.
                        That you have planning skills and great organizational skills, that you work
                        efficiently,independently, in a timely and cost-conscious manner - all of which I can emphasize.
                        But I also want to name another side of you: your sense of human moods, your commitment, the
                        ability to inspire and master difficult tasks confidently. They have always remained open and fair
                        and yet have achieved their common goals. Your new employer can tttok forward to a valuable
                        employee and give him my personal recommendation.
                    </p>
                    <span>Stefan Prunzel - bonCura direkt GmbH & Co. KG</span>
                </div>
                <div className="reference">
                    <p>
                        I can only warmly recommend Raimund, as we have got to know him as a Swiss distributor in his
                        active time at Mangar International with the products lifting cushions Elk and Camel as well as
                        bathing cushions. He is a very competent, focused and always honest person. We have come to know
                        Raimund not only as an excellent sales manager, but also as a good friend, which is very important
                        in our view for a lasting business relationship. Once again I can express my absolute
                        recommendation and I wish him all the best for his future career.
                    </p>
                    <span>P. Burri - DiMAZ - Direct Marketing Zurich AG</span>
                </div>
            </div>
        </div>
    );
}
