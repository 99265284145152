export default function News() {
    return (
        <>
            <h2>{{ de: "Neuigkeiten", en: "News" }[global.language]}</h2>
            <div>
                <b>30.06.2023</b>
                <br />
                {
                    {
                        de: `Es kann endlich losgehen! Der Startschuss ist gefallen und das Konzept "Lösungsfinder" ist bereit für
                            kommende Aufgaben.`,
                        en: `It can finally start! The starting signal was given and the "solution finder"
                            concept is ready for future tasks.`
                    }[global.language]
                }
            </div>
            <br />
            <div>
                <b>01.08.2023</b>
                <br />
                {
                    {
                        de: `Kooperation mit ExamVision (vertreten als Sales Manager Germany), einem der führenden Hersteller von
                            Lupenbrillen aus Dänemark. Für Terminvereinbarungen vor Ort oder im Showroom in Hamburg bitte `,
                        en: `Cooperation with ExamVision (represented as Sales Manager Germany), one of the leading manufacturers
                            of loupes from Denmark. For appointments on site or in the showroom in Hamburg, please `
                    }[global.language]
                }
                <a
                    href={`https://examvision.com/${global.language === "de" ? "de/" : ""}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {{ de: "Kontakt aufnehmen", en: "contact us" }[global.language]}
                </a>
                .
                <br />
                <img src="/examvision/ExamVision.jpg" alt="Examvision" style={{ maxHeight: 250, maxWidth: "100%" }} />
                <img
                    src="/examvision/logo-examvision.png"
                    alt="Logo Examvision"
                    style={{ maxHeight: 250, maxWidth: "100%" }}
                />
            </div>
            <br />
            <div>
                {
                    {
                        de: (
                            <p style={{ marginBottom: 0 }}>
                                <b>01.09.2023</b>
                                <br />
                                Kooperation mit Support Design (vertreten als Sales Manager Germany), Hersteller von
                                ergonomischen Sitzgelegenheiten, handgefertigt aus Schweden. Für Terminvereinbarungen
                                bitte Kontakt aufnehmen (
                                <a
                                    className="w3-hover-text-grey"
                                    href="tel:+491775620249"
                                    style={{ textDecoration: "none" }}>
                                    +49 (0) 177 562 0249
                                </a>{" "}
                                /{" "}
                                <a
                                    className="w3-hover-text-grey"
                                    href="mailto:info@raimundschmoll.de"
                                    style={{ textDecoration: "none" }}>
                                    info@raimundschmoll.de
                                </a>
                                ).
                            </p>
                        ),
                        en: (
                            <p style={{ marginBottom: 0 }}>
                                <b>01.09.2023</b>
                                <br />
                                Cooperation with Support Design (represented as Sales Manager Germany), manufacturer of
                                ergonomic seating, handmade from Sweden. Please contact me to arrange an appointment (
                                <a
                                    className="w3-hover-text-grey"
                                    href="tel:+491775620249"
                                    style={{ textDecoration: "none" }}>
                                    +49 (0) 177 562 0249
                                </a>{" "}
                                /{" "}
                                <a
                                    className="w3-hover-text-grey"
                                    href="mailto:info@raimundschmoll.de"
                                    style={{ textDecoration: "none" }}>
                                    info@raimundschmoll.de
                                </a>
                                ).
                            </p>
                        )
                    }[global.language]
                }
                <br />
                <img
                    src="/supportdesign/logo-supportdesign.jpg"
                    alt="Logo SupportDesign"
                    style={{ maxHeight: 250, maxWidth: "100%" }}
                />
                <img
                    src="/supportdesign/SupportDesign.jpg"
                    alt="Stühle neben einem Tisch"
                    style={{ maxHeight: 250, maxWidth: "100%" }}
                />
                <img
                    src="/supportdesign/raimundAtSupportDesign.jpg"
                    alt="Raimund bei SupportDesign"
                    style={{ maxHeight: 250, maxWidth: "100%" }} />
            </div>
            <div>
                {
                    {
                        de: (
                            <p style={{ marginBottom: 0 }}>
                                <b>01.09.2023</b>
                                <br />
                                Kooperation mit IGEA Medical (vertreten als Business Development Consultant), einem
                                biomedizinischen Unternehmen aus Italien und weltweit führend im Bereich der
                                biophysikalischen Therapien. Für Terminvereinbarungen bitte Kontakt aufnehmen (
                                <a
                                    className="w3-hover-text-grey"
                                    href="tel:+491775620249"
                                    style={{ textDecoration: "none" }}>
                                    +49 (0) 177 562 0249
                                </a>{" "}
                                /{" "}
                                <a
                                    className="w3-hover-text-grey"
                                    href="mailto:info@raimundschmoll.de"
                                    style={{ textDecoration: "none" }}>
                                    info@raimundschmoll.de
                                </a>
                                ).
                            </p>
                        ),
                        en: (
                            <p style={{ marginBottom: 0 }}>
                                <b>01.09.2023</b>
                                <br />
                                Cooperation with IGEA Medical (represented as a business development consultant), a
                                biomedical company from Italy and a global leader in the field of biophysical therapies.
                                Please contact me to arrange an appointment (
                                <a
                                    className="w3-hover-text-grey"
                                    href="tel:+491775620249"
                                    style={{ textDecoration: "none" }}>
                                    +49 (0) 177 562 0249
                                </a>{" "}
                                /{" "}
                                <a
                                    className="w3-hover-text-grey"
                                    href="mailto:info@raimundschmoll.de"
                                    style={{ textDecoration: "none" }}>
                                    info@raimundschmoll.de
                                </a>
                                ).
                            </p>
                        )
                    }[global.language]
                }
                <br />
                <img src="/IGEA/raimundAtIgea.jpg" alt="Raimund bei IGEA" style={{ maxHeight: 250, maxWidth: "100%" }} />
                <img src="/IGEA/Igea_Logo.png" alt="Logo IGEA" style={{ maxHeight: 250, maxWidth: "100%" }} />
            </div>
        </>
    );
}
