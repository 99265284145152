import { NavLink } from "react-router-dom";
import uk from "./images/united-kingdom.png";
import germany from "./images/germany.png";

export default function Header() {
    return (
        <header>
            <div>
                <img src="/logo.png" width={200} className="App-logo" alt="logo" />
                {
                    {
                        de: (
                            <div className="tooltip">
                                <button
                                    onClick={() => {
                                        window.location = "https://www.raimundschmoll.com" + window.location.pathname;
                                    }}>
                                    <img className="flagIcon" src={uk} alt="britishFlagIcon" />
                                </button>
                                <span className="tooltiptext">switch to english</span>
                            </div>
                        ),
                        en: (
                            <div className="tooltip">
                                <button
                                    onClick={() => {
                                        window.location = "https://www.raimundschmoll.de" + window.location.pathname;
                                    }}>
                                    <img className="flagIcon" src={germany} alt="germanFlagIcon" />
                                </button>
                                <span className="tooltiptext">zu deutsch wechseln</span>
                            </div>
                        )
                    }[global.language]
                }
            </div>
            <nav className="w3-bar">
                <NavLink className="w3-bar-item w3-button" to="/">
                    {
                        {
                            de: "Übersicht",
                            en: "Overview"
                        }[global.language]
                    }
                </NavLink>
                <NavLink className="w3-bar-item w3-button" to="/businessPackages">
                    {
                        {
                            de: "Lösungskonzepte",
                            en: "solution concepts"
                        }[global.language]
                    }
                </NavLink>
                <NavLink className="w3-bar-item w3-button" to="/team">
                    Team
                </NavLink>
                <NavLink className="w3-bar-item w3-button" to="/cologneconstitution">
                    {{ de: "meine DNA", en: "my DNA"}[global.language]}
                </NavLink>
                <NavLink className="w3-bar-item w3-button" to="/currentcustomers">
                    {{ de: "aktuelle Kunden", en: "current customers"}[global.language]}
                </NavLink>
                <NavLink className="w3-bar-item w3-button" to="/news">
                    News
                </NavLink>
            </nav>
        </header>
    );
}
