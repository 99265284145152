export default function CurrentCustomers() {
    return (
        <div style={{ textAlign: "left", width: "63%", margin: "0 auto" }}>
            <div className="customerContainer">
                <h3
                    className="diamond"
                    style={{ width: "fit-content", padding: 3, margin: "0 auto", borderRadius: "0.4rem" }}>
                    {{de: "Diamant-Kunde", en: "Diamond-Customer"}[global.language]}
                </h3>
                <div style={{ display: "inline-block" }}>
                    <h3>
                        ExamVision ApS{" "}
                        {
                            {
                                de: "(vertreten als Sales Manager für Deutschland)",
                                en: "(represented as Sales Manager for Germany)"
                            }[global.language]
                        }
                    </h3>
                    <h5>
                        {
                            {
                                de: "Preisgekrönte Lupenbrillen und LED-Lichtsysteme",
                                en: "Custom-crafted loupes for your unique needs"
                            }[global.language]
                        }
                    </h5>
                    <a
                        href={`https://examvision.com/${global.language === "de" ? "de/" : ""}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        https://examvision.com/{global.language === "de" ? "de/" : ""}
                    </a>
                </div>
                <img
                    src="/examvision/logo-examvision.png"
                    alt="Logo Examvision"
                    style={{ maxHeight: 200, maxWidth: "100%" }}
                />
            </div>
            <div className="customerContainer">
                <h3
                    className="diamond"
                    style={{ width: "fit-content", margin: "0 auto", padding: 3, borderRadius: "0.4rem" }}>
                    {{de: "Diamant-Kunde", en: "Diamond-Customer"}[global.language]}
                </h3>
                <div style={{ display: "inline-block" }}>
                    <h3>
                        Support Design AB{" "}
                        {
                            {
                                de: "(vertreten als Sales Manager für Deutschland)",
                                en: "(represented as Sales Manager for Germany)"
                            }[global.language]
                        }
                    </h3>
                    <h5>
                        {
                            {
                                de: "Design für Ihr Wohlbefinden",
                                en: "Design for your well-being"
                            }[global.language]
                        }
                    </h5>
                    <a
                        href={`https://www.supportdesign.se/${global.language}/`}
                        target="_blank"
                        rel="noopener noreferrer">
                        https://www.supportdesign.se/{global.language}/
                    </a>
                </div>
                <img
                    src="/supportdesign/logo-supportdesign.jpg"
                    alt="Logo SupportDesign"
                    style={{ maxHeight: 200, maxWidth: "100%" }}
                />
            </div>
            <div className="customerContainer">
                <h3
                    className="gold"
                    style={{ width: "fit-content", margin: "0 auto", padding: 3, borderRadius: "0.4rem" }}>
                    {{de: "Gold-Kunde", en: "Gold-Customer"}[global.language]}
                </h3>
                <div style={{ display: "inline-block" }}>
                    <h3>
                        IGEA S.p.A.{" "}
                        {
                            {
                                de: "(vertreten als Business Development Consultant)",
                                en: "(represented as Business Development Consultant)"
                            }[global.language]
                        }
                    </h3>
                    <h5>
                        {
                            {
                                de: "LEIDENSCHAFT, INNOVATION, FORSCHUNG",
                                en: "PASSION, INNOVATION, RESEARCH"
                            }[global.language]
                        }
                    </h5>
                    <a href={`https://www.igeamedical.com/${global.language}/`} target="_blank" rel="noopener noreferrer">
                        https://www.igeamedical.com/{global.language}/
                    </a>
                </div>
                <img src="/IGEA/Igea_Logo.png" alt="Logo IGEA" style={{ maxHeight: 200, maxWidth: "100%" }} />
            </div>
        </div>
    );
}
